import React, { useEffect, useRef, useState } from 'react';
import styles from "./home.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import aboutGameImg from "../../assets/images/aboutGame2.webp";
import aboutGameImg1 from "../../assets/images/aboutGame.webp";
import rummyImg from "../../assets/images/rummy.png";
import inactiveRummy from "../../assets/images/inactiverummy.png";
import ludoImg from "../../assets/images/ludo.png";
import activeLudo from "../../assets/images/activeludo.png";
import OurMission from '../ourMission/OurMission';
import Experience from '../experience/Experience';
import AboutUs from '../aboutUs/AboutUs';
import Products from '../products/Products';
import WorkWithUs from '../workWithUs/WorkWithUs';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import banner111 from "../../assets/images/banner111.webp";
import banner112 from "../../assets/images/banner112.webp";
import banner13 from "../../assets/images/banner13.webp";
import banner114 from "../../assets/images/banner114.webp";
import banner115 from "../../assets/images/banner115.webp";
import banner116 from "../../assets/images/banner116.webp";
import banner117 from "../../assets/images/banner117.webp";
import banner118 from "../../assets/images/banner118.webp";
import banner119 from "../../assets/images/banner119.webp";
import banner120 from "../../assets/images/banner120.webp";
import Navbar from '../navbar/Navbar';
import upbutton from "../../assets/images/up.png";
import ludo from "../../assets/images/ludobackground2.webp";
import ludo1 from "../../assets/images/ludobackground.webp";

import axios from "axios";
import { Helmet } from 'react-helmet';

const Home = () => {
    const [hide, setHide] = useState(true);
    const [showHide, setShowHide] = useState(false);
    const [width, setWidth] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [seoData, setSeoData] = useState<any>({});    

    const fetchSeo = async () => {
        try {
            let res = await axios.get('https://api.gaming.dev.arvattech.com/cms/seo/', {
                headers: {
                    BrandId: "5",
                }
            });
            console.log("API Response:", res?.data);
            setSeoData(res?.data);
            localStorage.setItem('seoData', JSON.stringify(res?.data));
        } catch (error) {
            console.error("Failed to fetch SEO data", error);
        }
    };

    useEffect(() => {
        
        function updateSize() {
            fetchSeo();
            setWidth(window.innerWidth);
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize); 
    }, []);


    const height = "100%";

    const items = [
        // <img src={banner121} alt="banner121" width={width} height={height} />,
        <img src={banner116} alt="banner116" width={width} height={height} />,
        <img src={banner117} alt="banner117" width={width} height={height} />,
        <img src={banner118} alt="banner118" width={width} height={height} />,
        <img src={banner119} alt="banner119" width={width} height={height} />,
        <img src={banner120} alt="banner120" width={width} height={height} />,
        <img src={banner115} alt="banner115" width={width} height={height} />,
        <img src={banner111} alt="banner111" width={width} height={height} />,
        <LazyLoadImage src={banner112} alt="banner112" width={width} height={height}  />,
        <LazyLoadImage src={banner13} alt="banner13" width={width} height={height}  />,
        <LazyLoadImage src={banner114} alt="banner114" width={width} height={height}  />,
    ];

    const refToTop = useRef<HTMLDivElement>(null);

    const onScroll = (event:any) => {
        const scrollPosition = event.target?.scrollingElement?.scrollTop ?? event.target.scrollTop;
        setScrollHeight(scrollPosition);
    };

    const scrollToTop = () => {
        refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll, { capture: true });
        return () => window.removeEventListener("scroll", onScroll, { capture: true });
    }, []);

    const scrollInterval = 5000;
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (hide) {
    //             handleClick1();
    //         } else {
    //             handleClick();
    //         }
    //     }, scrollInterval);

    //     return () => clearInterval(interval); // Cleanup interval on component unmount
    // }, [hide, showHide]);

    const handleClick = () => {
        setHide(true);
        setShowHide(false);
    };

    const handleClick1 = () => {
        setShowHide(true);
        setHide(false);
    };

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        console.log("SEO Data from LocalStorage:", seodatalocal);
    }, []);

    return (
        <>
            <Helmet>
            <title>Play Rummy & Ludo Online - Bet & Win Real Money</title>
                <meta name="description" content="Play popular online betting games like Rummy and Ludo. Win real cash and enjoy competitive gameplay on YourWebsite." />
                <meta name="keywords" content="rummy betting, ludo betting, online betting games, play rummy for cash, ludo cash games" />
                <link rel="canonical" href="https://www.vykagames.com" />
                <title>{seoData?.data?.[0].title}</title>
                <meta name="description" content={seoData?.data?.[0]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[0]?.keyword?.[0] || "Default Keywords"} />
                <link rel="preload" href="./home.module.scss" as="style" />
                <link rel="preload" href="../../assets/images/banner115.webp" as="image" />
                <link rel="preload" href="../../assets/images/banner111.webp" as="image" />
                <link rel="preload" href="../../assets/images/banner112.webp" as="image" />
                <link rel="preload" href="../../assets/images/banner13.webp" as="image" />
                <link rel="preload" href="../../assets/images/banner114.webp" as="image" />
                <link rel="preload" href="../../assets/images/aboutGame.webp" as="image" />
                <link rel="preload" href="../../assets/images/ludobackground.webp" as="image" />
            </Helmet>
            <section className={styles.home_cont} ref={refToTop}>
                <Navbar scrollHeight={scrollHeight} />
                <div className={styles.banner_cont}>
                    <AliceCarousel autoPlay autoPlayInterval={3000} infinite
                        disableDotsControls={false} disableButtonsControls={true} mouseTracking items={items} />
                </div>
            </section>
            <>
                <div className={styles.main_cont}>
                    {hide &&
                        <section className={`${styles.aboutGame_cont} ${hide ? styles.aboutGame_cont2 : ""}`}>
                            <div className={styles.abGame_subCont}>
                                <div className={styles.vyka_rummyImgCont}>
                                    <img className={styles.vykaRummy_img} src={aboutGameImg} alt='aboutGameImg'  />
                                    <img className={styles.vykaRummy_img1} src={aboutGameImg1} alt='aboutGameImg'  />
                                    
                                </div>
                                <div className={styles.aboutgame_text}>
                                    <span>Vyka Rummy</span> is a rummy website that prioritizes customer satisfaction and reliability. We thrive for a positive user experience, likely with features such as a user-friendly interface, secure transactions, and responsive customer support. We also emphasize fair play and responsible gaming practices
                                </div>
                            </div>

                        </section>
                    }

                    {
                        showHide &&
                        <section
                            className={`${styles.aboutGame_cont}
                         ${showHide ? styles.aboutGame_cont1 : ""}`}>
                            <div className={styles.abGame_subCont}>
                                <div className={styles.vyka_rummyImgCont}>
                                    <img className={styles.vykaRummy_img} src={ludo} alt="ludo"  />
                                    <img className={styles.vykaRummy_img1} src={ludo1} alt="ludo1"  />
                                </div>

                                <div className={styles.aboutgame_text}>
                                <span>Vyka Ludo</span> will prioritize both player satisfaction and platform reliability, ensuring a secure and enjoyable environment for Ludo enthusiasts. Stay tuned for more details and prepare for exciting Ludo gameplay!
                                </div>
                            </div>
                        </section>
                    }

                </div>
                <div className={styles.abtn_cont}>
                    {
                        hide ? <div className={styles.rummyImg_cont} onClick={handleClick}>
                            <img className={styles.rummyImg} src={rummyImg} alt='rummyImg' />
                        </div> : <div className={styles.rummyImg_cont} onClick={handleClick}>
                            <img className={styles.inactiveRummyImg} src={inactiveRummy} alt='inactiveRummy' />
                        </div>
                    }

                    {
                        showHide ?

                            <div className={styles.ludoImg_cont} onClick={handleClick1}>
                                <img className={styles.activeLudo} src={activeLudo} alt='activeLudo' />
                            </div> :
                            <div className={styles.ludoImg_cont} onClick={handleClick1}>
                                <img className={styles.ludoImg} src={ludoImg} alt='ludoImg' />
                            </div>
                    }
                </div>
            </>


            <OurMission />
            <Experience />
            <AboutUs />
            <Products />
            <WorkWithUs />

            <div className={scrollHeight > 55 ? `up_btncont` : `up_btncontnone`} onClick={scrollToTop}>
                <img className={styles.up_btn} src={upbutton} alt='upbutton' />
            </div>
        </>
    );
}

export default Home;
